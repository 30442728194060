import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
};
// ----------------------------------------------------------------------
export const confirmAccount = createAsyncThunk('account/confirmAccount', async ({ code }) => {
    let data;
    try {
        const response = await axios.put('/users/confirmAccount', { code });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'confirmAccount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(confirmAccount.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(confirmAccount.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(confirmAccount.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
